import React from "react"
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap"

class Header extends React.Component {
  constructor(props) {
    super(props)

    this.toggle = this.toggle.bind(this)
    this.state = {
      isOpen: false,
    }
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    })
  }

  render() {
    return (
      <>
        <Navbar
          fixed="top"
          className="navbar-custom"
          color="faded"
          light
          expand="md"
        >
          <div className="container">
            <NavbarBrand className="hvc-logo">
              {/* <NavLink href="/"> */}
                <h4>High Voltage Code</h4>
              {/* </NavLink> */}
            </NavbarBrand>

            <NavbarToggler
              onClick={this.toggle}
              // style={{
              //   border: "1.5px solid white",
              //   borderRadius: "5px",
              //   outline: "0",
              //   background: "transparent",
              // }}
            />
            <Collapse isOpen={this.state.isOpen} navbar>
              <Nav className="ml-auto mb-0 centered-nav-menu" navbar>
                <NavItem>
                  <NavLink
                    style={{ fontWeight: "500" }}
                    href="/"
                  >
                    Home
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="/tutorials"
                  >
                    Tutorials
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="/about"
                  >
                    About
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="/contact"
                  >
                    Contact Me
                  </NavLink>
                </NavItem>
              </Nav>
            </Collapse>
          </div>
        </Navbar>
        {/*ADD SOME HEADER PICTURE MAYBE HERE */}
      </>
    )
  }
}

export default Header
