
import React from "react"
import MailchimpSubscribe from "react-mailchimp-subscribe"

const url =
  "//gmail.us3.list-manage.com/subscribe/post?u=b003d767ce261f1da79759c86&amp;id=7dd9c5e11f"

// simplest form (only email)
//const SimpleForm = () => <MailchimpSubscribe url={url}/>

// use the render prop and your custom form
const SimpleForm = () => (
  <MailchimpSubscribe
    url={url}
    render={({ subscribe, status, message }) => (
      <div>
        <CustomForm
          onSubmitted={formData => subscribe(formData)}
          status={status}
          message={message}
          onValidated={formData => subscribe(formData)}
        />
      </div>
    )}
  />
)

// a basic form
const CustomForm = ({ status, message, onValidated }) => {
  let email
  const submit = () =>
    email &&
    email.value.indexOf("@") > -1 &&
    onValidated({
      EMAIL: email.value,
    })

  return (
    <div>
      {status === "sending" && <div style={{ color: "blue" }}>sending...</div>}
      {status === "error" && (
        <div
          style={{ color: "red" }}
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
      {status === "success" && (
        <div
          style={{ color: "green" }}
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}

      <input
        style={{ textAlign: "center", marginTop: "15px" }}
        ref={node => (email = node)}
        type="email"
        placeholder="Your email"
      />
      <br />
      <button
        style={{
          marginTop: "10px",
          borderRadius: "5px",
          padding: "3px 10px",
          color: "#007bff",
          background: "transparent",
          border: "none",
          textTransform: "uppercase",
          fontWeight: "500",
          outline: "0",
        }}
        onClick={submit}
      >
        Submit
      </button>
    </div>
  )
}

export default SimpleForm
