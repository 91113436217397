import React from "react"
//import AdSense from "react-adsense"
import {
  
  Card,
  CardTitle,
  CardBody,
  
  CardText,
} from "reactstrap"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Tags from "./tags"
import SimpleForm from "./subscribe";

const Sidebar = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        sort: { fields: [frontmatter___date], order: DESC }
        limit: 3
      ) {
        edges {
          node {
            id
            frontmatter {
              title
              image {
                childImageSharp {
                  fluid(maxWidth: 300) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            fields {
              slug
            }
          }
        }
      }
      file(relativePath: { eq: "link_git.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <div>
      <Card>
        <CardBody >
          <CardTitle className="text-center text-uppercase">
            <h6>Categories</h6>
          </CardTitle>
          <Tags />
        </CardBody>
      </Card>
      <Card className="small-hidden">
        <Img
          className="card-image-top card-image"
          fluid={data.file.childImageSharp.fluid}
        />
        <CardBody>
          <CardTitle className="text-center mb-3">
            Joseph (Juozas)
            <CardText>Software developer with sick passion for electronics!</CardText>
          </CardTitle>
        </CardBody>
      </Card>
      
      
      {/*<Card>
        <CardBody>
          <CardTitle className="text-center text-uppercase">
            Advertisement
          </CardTitle>
          <AdSense.Google client="ca-pub-2169032777005592" /*slot="FIGURE ME OUT"*/ /*style={{ width: "100%" }}//} />
          
        </CardBody>
      </Card>*}*/}


      

      
        <Card className="justify text-center padded" style={{paddingBottom: "15px"}}>
          <CardBody>
          <CardTitle >

            <h6>NEWSLETTER</h6>
          </CardTitle>
          Subscribe to get all the latest updates on new projects and other news from High Voltage Code!
          </CardBody>
          
         
          <SimpleForm/>
          </Card>
          
          
      
    </div>
  )
}
export default Sidebar
