import React from "react"
import { Link } from "gatsby"


const Subheader = ({title}) => {
  return (
    <div className="subheader">
        <div>
            <Link to={`/`} style={{ textDecoration: 'none' }}><h2 className="font-weight-light mt-7 text-white">{title}</h2></Link>
            <p className="lead text-white-50">Electronics and programming tutorials. Learn by building things!</p>
            
        </div>
      
      
    </div>
  )
}
export default Subheader
