/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"
import "../styles/index.scss"
import { Row, Col } from "reactstrap"
import Footer from "./footer"
import Header from "./header"
import Sidebar from "./sidebar"
import Subheader from './subheader'
import CookieConsent from "react-cookie-consent"

const Layout = ({ children, pageTitle }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `)

  return (
    <>
      {/* Stuff that goes into the head */}
      <script
        data-ad-client="ca-pub-2169032777005592"
        async
        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
      ></script>

      {/* Umported directly */}
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.9.0/css/all.css"
      />

      {/* End of stuff that goes into the head */}

      <Header siteTitle={data.site.siteMetadata.title} />
      {/* Might add subheader here */}
      <Subheader title={data.site.siteMetadata.title} description={data.site.siteMetadata.description}/>

      <div className="container" id="content">
        <div className="text-center page-title border-after">
          <h4>{pageTitle}</h4>
        </div>

        <Row>
          <Col lg="9" md="12">
            {children}
          </Col>
          <Col lg="3" md="0">
            <Sidebar />
          </Col>
        </Row>
      </div>
      <Footer />
      <CookieConsent
        style={{
          background: "#187bff",
          font: "sans-serif",
          fontSize: "15px",
          fontWeight: "bold",
        }}
        buttonStyle={{
          color: "#4e503b",
          background: "white",
          borderColor: "#2e6da4",
          font: "sans-serif",
          fontWeight: "bold",
        }}
      >
        This website uses cookies to enhance the user experience. Click{" "}
        <Link
          style={{
            color: "white",
            font: "sans-serif",
            textDecoration: "underline",
          }}
          to="/privacy"
        >
          here
        </Link>{" "}
        to learn more.
      </CookieConsent>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
