import React from "react"
import { Link } from "gatsby"
import { Container, Row, Col } from "reactstrap"

const Footer = () => {
  return (
    <div className="site-footer">
      <Container>
        <Row className="justify-content-md-center">
       
          <Col lg="2" className="padded">
           © Juozas Polikevicius{' ' + new Date().getFullYear()}
          </Col>
          <Col className="md-auto">
            <div className="footer-social-links">
              <ul className="social-links-list">
                <li>
                  <a
                    href="https://www.facebook.com/juozas.polikevicius"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="facebook"
                  >
                    <i className="fab fa-facebook fa-2x" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.youtube.com/channel/UCOm4NboFREQr-AIUtZLh21Q/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="youtube"
                  >
                    <i className="fab fa-youtube fa-2x" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/in/juozas-polikevicius-222816145/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="linkedin"
                  >
                    <i className="fab fa-linkedin fa-2x" />
                  </a>
                </li>

                <li>
                  <a
                    href="https://github.com/Onixaz"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="github"
                  >
                    <i className="fab fa-github fa-2x" />
                  </a>
                </li>
              </ul>
            </div>
          </Col>

          <Col lg="2" className="padded privacy-terms-links">
            
             <div><Link to="/privacy">Privacy Policy</Link></div>
             
              <div><Link to="/terms">Terms of Service</Link></div>
            
          </Col>
        
        </Row>
      </Container>
     
    </div>
  )
}
export default Footer
