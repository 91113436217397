import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { slugify } from "../utils/utilityFunctions"
import { Badge } from "reactstrap"
const _ = require("lodash")

const Tags = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark {
        edges {
          node {
            frontmatter {
              tags
            }
          }
        }
      }
    }
  `)

  const posts = data.allMarkdownRemark.edges

  //get all tags
  let tags = []
  _.each(posts, edge => {
    if (_.get(edge, "node.frontmatter.tags")) {
      tags = tags.concat(edge.node.frontmatter.tags)
    }
  })

  //get tag post count
  let tagPostCounts = {}
  tags.forEach(tag => {
    tagPostCounts[tag] = (tagPostCounts[tag] || 0) + 1
  })
  tags = _.uniq(tags)

  return (
    
    <ul className="nonstyledlist">
      {tags.map(tag => (
        <li key={tag} style={{ marginBottom: "10px" }}>
          <a href={`/tag/${slugify(tag)}`}>
          <button className="btn btn-primary">
            {tag} <Badge color="light">{tagPostCounts[tag]}</Badge>
          </button>
          </a>
        </li>
      ))}
    </ul>
    
  )
}
export default Tags
